<template>
  <b-row class="page-privacy">
    <b-col>
      <PageHeader :title="$t('general.privacyPage.title')" />

      <div class="page-privacy__settings">
        <div
          class="page-privacy__row"
          v-for="button in buttonList"
          :key="button.id"
        >
          <div class="page-privacy__text">
            <span class="page-privacy__font">
              {{ button.name }}
            </span>
          </div>

          <span class="page-privacy__addition">
            <CButtonToggle
              class="page-privacy__toggle"
              :class="{ 'page-privacy__toggle--disabled': button.disabled }"
              :is-active="button.isActive"
              @click="button.action"
            />
          </span>
        </div>
      </div>

      <div class="page-privacy__button-wrapper">
        <button
          type="button"
          @click.prevent="setSettings"
          class="base-button page-privacy__button"
        >
          <span class="base-button__font">
            {{ $t("general.privacyPage.button.update") }}
          </span>
        </button>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import PageHeader from "@/layout/PageHeader.vue";
import CButtonToggle from "@/features/ui/CButtonToggle.vue";

import validationError from "@/mixins/validationError";
import waitRequest from "@/mixins/waitRequest";

const activityStatusType = {
  hideAll: 0,
  showActivity: 1,
  hideActivity: 2,
};

export default {
  name: "PagePrivacy",
  mixins: [validationError, waitRequest],
  components: {
    CButtonToggle,
    PageHeader,
  },
  data() {
    return {
      settings: null,
      isShowActivityStatus: false,
      isHideActivityStatus: false,
    };
  },

  computed: {
    buttonList() {
      return [
        {
          id: 1,
          name: this.$t("general.privacyPage.button.activityStatus"),
          isActive: this.isShowActivityStatus,
          action: () =>
            (this.isShowActivityStatus = !this.isShowActivityStatus),
        },
        {
          id: 2,
          name: this.$t("general.privacyPage.button.hideActivity"),
          isActive: this.isShowActivityStatus && this.isHideActivityStatus,
          action: () =>
            (this.isHideActivityStatus = !this.isHideActivityStatus),
          disabled: !this.isShowActivityStatus,
        },
      ];
    },
  },

  mounted() {
    this.initState();
  },

  methods: {
    initState() {
      this.getSettings();
    },
    getSettings() {
      if (this.requestInProgress) return;

      return this.waitRequest(() => {
        return this.$get(
          "/profile/settings",
          (data) => {
            this.settings = data;
            this.setActiveStatus();
          },
          (err) => {
            console.log("err", err);
          }
        );
      });
    },

    setSettings() {
      if (this.requestInProgress) return;

      const settings = {
        ...this.settings?.userSettings,
        is_show_activity_status: this.isShowActivityStatus,
        show_activity_only_for_subscribers: this.isHideActivityStatus,
      };

      return this.waitRequest(() => {
        return this.$post(
          "/profile/settings",
          settings,
          () => {},
          () => {
            this.settings = {};
          },
          this.checkErrors
        );
      });
    },

    setActiveStatus() {
      const activityStatus = this.settings?.userSettings?.show_activity_status;
      this.isShowActivityStatus = [
        activityStatusType.showActivity,
        activityStatusType.hideActivity,
      ].includes(activityStatus);

      this.isHideActivityStatus =
        activityStatus === activityStatusType.hideActivity;
    },
  },
};
</script>

<style scoped lang="scss">
.page-privacy {
  $parent: &;

  &__settings {
    margin-top: em(24);
    margin-left: em(-15);
    margin-right: em(-15);
    padding: 0 em(17);
    border-bottom: 1px solid $app-gray-2;
  }

  &__row {
    display: flex;
    align-items: center;
    margin-bottom: em(19);

    &:last-of-type {
      margin-bottom: em(31);
    }
  }

  &__text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: background-color $time-normal $ease, color $time-normal $ease;
  }

  &__font {
    color: $black;
    font-family: $font-default;
    font-size: em(14);
    font-style: normal;
    font-weight: 500;
  }

  &__addition {
    margin-left: auto;
    display: inline-flex;
    align-items: center;
  }

  &__button {
    margin-top: em(17);
    margin-left: auto;
  }

  &__button-wrapper {
    display: flex;
  }

  &__toggle {
    font-size: 60%; // change toggle default size

    &--disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &__icon {
    display: inline-block;
    width: em(18);
    min-width: em(18);
    height: em(18);
    margin-right: em(13);
  }
}

.base-button {
  $parent: &;
  width: auto;

  display: inline-flex;
  align-items: center;

  border-radius: em(100, 24);
  border: 1px solid $app-blue;
  background-color: $app-blue;
  padding: em(7, 16) em(16, 16);
  color: $white;

  &__font {
    color: $white;
    text-align: center;
    font-family: $font-default;
    font-size: em(12, 16);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
}
</style>
